import React, { useState, useEffect } from 'react';
import './PopUp.css';


function PopUp() {
    const [open, setOpen] = useState(false);

    const handleOpenClick = () => {
        setOpen(true);
    }

    const handleCloseClick = () => {
        setOpen(false);
    }

    useEffect(() => {
        const handleKeyUp = (event) => {
          if (event.keyCode === 27) { // "ESC" key
            handleCloseClick();
          }
        };
        window.addEventListener('keyup', handleKeyUp);
    
        const handlePopState = () => {
          handleCloseClick();
        };
        window.addEventListener('popstate', handlePopState);
    
        return () => {
          window.removeEventListener('keyup', handleKeyUp);
          window.removeEventListener('popstate', handlePopState);
        };
      }, []);


    return (
        <div >
            <div className="box">
                <button className="book_button" onClick={handleOpenClick}>BOKNING</button>
            </div>

            {open && (
                <div id="popup" className="overlay">
                    <div className="popup">
                        <h3>Bokning/Avhämtning:</h3>
                        <p className='tell_num'>0470-46800</p>
                        <div className="content">
                            <button className="close content_btn" onClick={handleCloseClick}>Avbryt</button>
                            <a className="call content_btn" href="tel:0470-46800">Call</a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
export default PopUp