import React from 'react';
import './Banner.css';

function Banner() {
  return (
    <div className='banner_part'>
      <div className='banner'>
        <div className='banner_text'>
          <h1>Välkommen</h1>
          {/* <p>You will make a great choice</p> */}
          <p>Välkommen till Växjös äldsta kinarestaurang.</p>
        </div>
      </div>
    </div>

  )
}

export default Banner
