
export const weeklyMenu =
{
    "Måndag": {
        "number": "30",
        "dish": "Friterad Kyckling M. Sötsursås",
        "price": {
            "old": 119,
            "new": 99
        }
    },
    "Tisdag": {
        "number": "73",
        "dish": "Stekta Nudlar M. Kyckling, Biff El. Fläsk",
        "price": {
            "old": 119,
            "new": 99
        }
    },
    "Onsdag": {
        "number": "38",
        "dish": "Kyckling På Spett M. Jordnötssås",
        "price": {
            "old": 119,
            "new": 99
        }
    },
    "Torsdag": {
        "number": "37",
        "dish": "Kyckling Blandade Grönsaker",
        "price": {
            "old": 119,
            "new": 99
        }
    },
    "Söndag": {
        "number": "0",
        "dish": "Fläskfile med ostronsås (kryddad)",
        "price": {
            "old": 119,
            "new": 99
        }
    }
}
