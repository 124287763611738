import React from 'react';
import Banner from '../../components/banner/Banner';
import NavBox from '../../components/navBox/NavBox';
import Recommend from '../../components/recommendation/Recommend';
import Buffet from '../../components/buffet/Buffet';
import Information from '../../components/information/Information';
import Promote from '../../components/promote/Promote';


function Home() {
  return (
    <div className="Home">
        <Banner />
        <NavBox />
        <Promote />
        <Buffet />
        <Recommend />
        <Information />
    </div>
  );
}

export default Home;
