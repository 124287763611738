import React from 'react';
import Dishes from './Dishes';
import "./Category.css";
import './Dishes.css';

function Category({menu, categories, price_type}) {
    const current_menu = menu.filter((dish) => dish.category.en === categories.name.en);
    return (
        <div className='category'>
            <h1 className='category_title'>{categories.name.sv} / {categories.name.en}</h1>
            <img className='category_image' src={categories.img} alt={categories.description} />

            <div className='menu_list'>
                {current_menu.map((dish) => (
                    <Dishes dish={dish} key={dish.id} price_type={price_type} className='dishes_list_for_menu'
                    />
                ))}
            </div>

        </div>
    )
}

export default Category;
