import React from 'react'
import { weeklyMenu } from './PromoteData.js';
import './Promote.css'

// const weeklyMenu = weeklyMenu;

function Promote() {
    return (
        <div className='promote_part'>
            <div className='promote'>
                <div className='promote_title'>
                    <h2>Söndag - Torsdag (för takeaway)</h2>
                    <p>Veckans specialerbjudanden </p>
                </div>
                <div className='promote_content'>
                    {Object.keys(weeklyMenu).map((item) => {
                        return (
                            <div className='promote_card' key={item} id={item}>
                                <h3>{item}</h3>
                                <p>num.{weeklyMenu[item].number}</p>
                                <h4>{weeklyMenu[item].dish}</h4>
                                <h3 className='promote_price'>Rea {weeklyMenu[item].price.new}:-</h3>
                                <p className='old_price'>{weeklyMenu[item].price.old}:-</p>
                            </div>
                        )
                    })
                    }
                </div>
            </div>
        </div>
    )
}

export default Promote
