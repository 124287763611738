import React from 'react';
import "./Dishes.css";

function Dishes({dish, price_type, className}) {
    return (
        <div 
        className={`dishes_list ${className}`}
        key={dish.id}
        name={dish.name.en}
        >
            <div className='dish_title'>
                <h3 className='number'>{dish.id}.</h3>
                <h3 className='sv_name'>{dish.name.sv}</h3>
            </div>
            <div className='dish_info'>
                <p className='en_name'>{dish.name.en}</p>
                <p className='dish_price'>{price_type === 'menu' ? dish.price.sit : dish.price.delivery}</p>
            </div>
        </div>
    )
}

export default Dishes
