import React from 'react';
import { Link } from 'react-router-dom';
import './NavBox.css';

import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import RamenDiningIcon from '@mui/icons-material/RamenDining';
import FiberNewIcon from '@mui/icons-material/FiberNew';


function NavBox() {

  return (
    <div className='navBox_part'>
      <div className='navBox'>

        <a href='#buffet_part' className='navBox_item'>
          <div className='nav_card buffet'>
            <RamenDiningIcon id='buffet_icon' />
            <h1 className='navBox_item-1'>Buffé <FiberNewIcon sx={{ color: '#FF2D00' }} /> </h1>
          </div>
        </a>

        <Link to='/menu?price=menu' className='navBox_item' >
          <div className='nav_card'>
            <RestaurantMenuIcon id='buffet_icon' />
            <h1 className='navBox_item-1'>meny</h1>
          </div>
        </Link>

        <Link to='/menu?price=delivery' className='navBox_item' >
          <div className='nav_card'>
            <DeliveryDiningIcon id='buffet_icon' />
            <h1 className='navBox_item-1'>Takeaway</h1>
          </div>
        </Link>
      </div>
    </div>

  )
}

export default NavBox