import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/navBar/NavBar';
import Home from './pages/homePage/Home';
import Footer from './components/footer/Footer';
import Menu from './pages/menuPages/Menu';


function App() {
  return (
    <div className='App'>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/menu' element={<Menu />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  )
}

export default App;
