import React from 'react';
import './Recommend.css';
import Unit from './Unit';

import { Data_dishes } from '../../pages/menuPages/MENUDATA';
const menuData = Data_dishes;

function Recommend() {
    const topOfTakeaway_id = ["75", "76", "36"];
    const topOfOrder_id = ["43", "32", "36"];


    const topOfTakeaway_menu = topOfTakeaway_id.flatMap((id) => menuData.filter((dish) => dish.id === id));
    const topOfOrder_menu = topOfOrder_id.flatMap((id) => menuData.filter((dish) => dish.id === id));


    return (
        <div className='recommend_part'>
            <div className='recommend'>
                <div className='recommend_title'>
                    <h1>Populära rätter</h1>
                    <p>Det är här du hittar våra favoriter.</p>
                    <p>Baserat på vår försäljningsdata (förra månaden):</p>
                </div>
                <div className='recommend_categories'>
                    <Unit  
                        unit_title={"Takeaway bästsäljare"}
                        current_menu={topOfTakeaway_menu}
                        price_type={"delivery"}
                    />
                    <Unit
                        unit_title={"In-house toppval"}
                        current_menu={topOfOrder_menu}
                        price_type={"menu"}
                     />
                </div>
            </div>
        </div>

    )
}

export default Recommend;
