import React from 'react';
import './Footer.css';

function Footer() {
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <div className='footer_part'>
      <div className='footer'>
        <div className='footer_container'>
          <img className='footer_logo' src={require('../../pics/HongKong.png')} alt='logo' onClick={scrollToTop}/>
        </div>
      </div>
    </div>
  )
}

export default Footer
