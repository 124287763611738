import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Category from './Category';
import { Data_dishes } from './MENUDATA';
import { CategoriesData } from './CategoriesData';
import './Menu.css';


import MenuBookIcon from '@mui/icons-material/MenuBook';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';

const MenuData = Data_dishes;


function Menu() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const price = searchParams.get('price');

    const [menu] = useState(MenuData);
    const [categories] = useState(CategoriesData);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='menu_part'>
            <div className='menu_title'>{
                price === 'menu' ?
                    <div className='menu_title_item'>
                        <p>Restaurangmeny</p>
                        <MenuBookIcon id='menu_title_item_icon' />
                        <p>In-House Menu</p>
                    </div>
                    :
                    <div className='menu_title_item'>
                        <p>Avhämtningsmeny</p>
                        <DeliveryDiningIcon id='menu_title_item_icon' />
                        <p>Takeaway Menu</p>
                    </div>
            }
            </div>

            <div className='allergy_tips'>
                <div className='tips'>
                    <h3>För allergiker</h3>
                    <p>Laktosintolerant: Ingen mjölk eller grädde finns i vara rätter (förutom efterrätterna). Glutenintolerant: Undvik friterat, rätter med curry eller jordnötssas och visa nudlar. Fraga garna personalen om niàr osäkra.</p>
                </div>

                <div className='tips'>
                    <h3>För barn</h3>
                    <p>Vill ni ha barnportion ar det bara att säga till. Da fär ni hälften av rätten för ett billigare pris (med vissa undantag).</p>
                </div>
            </div>

            <div className='menu'>
                <Category menu={menu} categories={categories[0]} price_type={price} />
                <Category menu={menu} categories={categories[1]} price_type={price} />
                <Category menu={menu} categories={categories[2]} price_type={price} />
                <Category menu={menu} categories={categories[3]} price_type={price} />
                <Category menu={menu} categories={categories[4]} price_type={price} />
                <Category menu={menu} categories={categories[5]} price_type={price} />
            </div>


        </div>
    )
}

export default Menu
