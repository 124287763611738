import React from 'react';
import './Unit.css';

import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';

import Dishes from '../../pages/menuPages/Dishes';

function Unit({unit_title,current_menu, price_type}) {
    return (
        <div className='unit'>
            <div className='unit_title'>
                <h3>{unit_title} 
                    <MilitaryTechIcon sx={{color: '#a37409'}}/>
                    <MilitaryTechIcon sx={{color: '#a37409'}}/>
                    <MilitaryTechIcon sx={{color: '#a37409'}}/>
                </h3>
            </div>
            <div className='unit_dishes'>
                {current_menu.map((dish) => (
                    <Dishes className='testing' dish={dish} key={dish.id} price_type={price_type}
                    />
                ))}
            </div>
        </div>
    )
}

export default Unit
