import React from 'react';
import './Buffet.css';
import FiberNewIcon from '@mui/icons-material/FiberNew';

function Buffet() {
    return (
        <div className='buffet_part' id='buffet_part'>
            <div className='buffet_section'>
                <div className='buffet_intro'>
                    <h1 className='buffet_title'>Buffé</h1>
                    <p className='buffet_subtitle'>Àt sa mycket du vill och orkar! </p>
                    {/* <p className='buffet_subtitle'><p className='buffet_subtitle'>All you can eat</p></p> */}
                </div>
                <div className='buffet_categories'>
                    <div className='buffet_card buffet_dinner'>
                        <img className='buffet_img' src={require('../../pics/Mongolian_Buffet.jpg')} alt='dinner buffet in restaurantHongKong' />
                        <div className='buffet_description'>
                            <div className='buffet_heading'>
                                {/* <h3 className='buffet_title'>Mongolian Buffet</h3> */}
                                <h3 className='buffet_title'>
                                    Middags Buffé (Mongolisk + Varmrätter )
                                    <FiberNewIcon sx={{ color: '#FF2D00' }} />
                                </h3>
                                {/* <p className='buffet_subtitle'>215,00 kr / st</p> */}
                                <div className='buffet_times_price'>
                                    <p className='buffet_subtitle'>Män: Stängt kl 16.00</p>
                                    <p className='buffet_subtitle'>Tis-Fre: 16:00 - 22:00 -- SEK 219 /st</p>
                                    <p className='buffet_subtitle'><span className='letter_space'>Lör-Sön</span>: 17:00 - 23:00 -- SEK 219 /st</p>
                                </div>
                            </div>
                            <p>Hej kära kunder! 🤝😁
                                <br />Nu finns det även varmrätter på den mongoliska buffe.
                                <br />Varmt välkomna!😁💞🤗</p>
                        </div>
                    </div>
                    <div className='buffet_card'>
                        <img className='buffet_img' src={require('../../pics/noodle.jpg')} alt='dinner buffet in restaurantHongKong' />
                        <div className='buffet_description'>
                            <div className='buffet_heading'>
                                <h3 className='buffet_title'>Lunch Buffé</h3>
                                {/* <p className='buffet_subtitle'>135,00 kr / st</p> */}
                                <div className='buffet_times_price'>
                                    <p className='buffet_subtitle'>Mån: 11:00 - 16:00 -- SEK 125 /st</p>
                                    <p className='buffet_subtitle'>Tis-Fre: 11:00 - 15:00 -- SEK 125 /st</p>
                                    <p className='buffet_subtitle'><span className='letter_space'>Lör-Sön</span>: 11:30 - 16:00 -- SEK 145 /st</p>
                                </div>
                            </div>
                            <p>Njut av 10+ huvudrätter, sallader, stekta läckerheter och autentisk sushi. <br />Krisiga godsaker, fräscha sallader sprängfyllda med smaker.<br /> En sann kulinarisk resa med full tillfredsställelse.</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default Buffet
