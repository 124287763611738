import React from 'react';
import './Information.css';

import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import LocationOnIcon from '@mui/icons-material/LocationOn';

function Information() {
    return (
        <div className='information_part' id='information_part'>
            <div className='information'>
                <div className='contact_information'>
                    <div className='info_item'>
                        {/* <h3>Opening Hours</h3> */}
                        <h3>Öppettider</h3>
                        <div className='hours'>
                            {/* <p><span className='icon'><LocalCafeIcon /></span>Monday - Friday: 11:00 - 22:00</p> */}
                            <p><span className='icon'><LocalCafeIcon /></span>Måndag - Fredag: 11:00 - 22:00</p>
                            {/* <p><span className='icon'><LocalBarIcon /></span>Saturday - Sunday: 12:00 - 23:00</p> */}
                            <p><span className='icon'><LocalBarIcon /></span>Lördag - Söndag: 11:30 - 23:00 / 22:00</p>
                        </div>
                    </div>

                    <div className='info_item'>
                        <h3>Tel & Address</h3>
                        <p><span className='icon'><PhoneInTalkIcon /></span>
                             <a className="info_tell_num" href="tel:0470-46800">0470-46800</a>
                        </p>
                        <p><span className='icon'><LocationOnIcon /></span>
                            <a className='info_address' href='https://www.google.com/maps/search/?api=1&query=Restaurant+Hong+Kong%2C+Storgatan+33%2C+352+30+Växjö' target="_blank" rel="noopener noreferrer"> Storgatan 33, 352 30 Växjö </a>
                        </p>
                    </div>
                </div>


                <div className='about'>
                    <h2>Om oss</h2>
                    <p>Välkommen till Växjös äldsta kinarestaurang.
                        <br/> Hong Kong ar en familjeagd restaurang där du kan njuta av mat fran det asiatiska köket. Vi har lunchbuffé under dagen där du kan välja mellan ett stort antal rätter plus pafyllning av dricka! Under kvällen har vi den mongoliska buffén där du själv kan bestämma innehall och smak.</p>
                </div>

                
            </div>
        </div>

    )
}

export default Information
