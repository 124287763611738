import React from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';



import DragHandleIcon from '@mui/icons-material/DragHandle';
// import navLogo from '../../pics/HongKong.png';
import navLogo2 from '../../pics/logo.png';
import PopUp from '../pop-up/PopUp';

function Navbar() {
    return (
        <div className='navBar_part'>
            <div className='navbar'>
                <div className='nav_logo'>
                    <Link to='/' className='logo_image'>
                        <img className='logo_image' src={navLogo2} alt='logo' />
                    </Link>
                </div>
                <div className='nav_list'>
                    {/* <p className='nav_item'><Link to='/' className='nav_item'>Home</Link></p> */}
                    <PopUp className='nav_item' />
                    {/* <p className='nav_item'>Bokning</p> */}
                    {/* <a className='nav_item opening_hours' href='#information_part' >Öppettider</a> */}

                </div>
                <div className='nav_drag'>
                    < DragHandleIcon className='drag' />
                </div>
            </div>
        </div>
    )
}

export default Navbar
