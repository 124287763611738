export const Data_dishes = [
// 7 starters 1 - 7
  {
    "id": "1",
    "category": {
      "en": "starters",
      "sv": "Förrätter"
    },
    "name": {
      "en": "roller",
      "sv": "värrulle"
    },
    "price": {
      "sit": "55",
      "delivery": "55"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "2",
    "category": {
      "en": "starters",
      "sv": "Förrätter"
    },
    "name": {
      "en": "deep fried wonton",
      "sv": "friterade wonton"
    },
    "price": {
      "sit": "69",
      "delivery": "55"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "3",
    "category": {
      "en": "starters",
      "sv": "Förrätter"
    },
    "name": {
      "en": "wonton soup",
      "sv": "wontonsoppa"
    },
    "price": {
      "sit": "69",
      "delivery": "55"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "4",
    "category": {
      "en": "starters",
      "sv": "Förrätter"
    },
    "name": {
      "en": "crabfish soup",
      "sv": "soppa med crabfish"
    },
    "price": {
      "sit": "55",
      "delivery": "55"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "5",
    "category": {
      "en": "starters",
      "sv": "Förrätter"
    },
    "name": {
      "en": "soup with chicken and mushrooms",
      "sv": "soppa med kyckling och champinjoner"
    },
    "price": {
      "sit": "55",
      "delivery": "55"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "6",
    "category": {
      "en": "starters",
      "sv": "Förrätter"
    },
    "name": {
      "en": "peaking soup",
      "sv": "pekingsoppa"
    },
    "price": {
      "sit": "55",
      "delivery": "55"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "7",
    "category": {
      "en": "starters",
      "sv": "Förrätter"
    },
    "name": {
      "en": "shrimp chips",
      "sv": "räkchips"
    },
    "price": {
      "sit": "35",
      "delivery": "35"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },

  // 17 Meat dishes: 10 - 18 / 20 - 27
  {
    "id": "10",
    "category": {
      "en": "meat",
      "sv": "Kötträtter"
    },
    "name": {
      "en": "beef with onions & leeks",
      "sv": "Biff med Purjolök"
    },
    "price": {
      "sit": "149",
      "delivery": "129"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "11",
    "category": {
      "en": "meat",
      "sv": "Kötträtter"
    },
    "name": {
      "en": "beef with oyster sauce",
      "sv": " Biff med Ostronsås"
    },
    "price": {
      "sit": "149",
      "delivery": "129"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "12",
    "category": {
      "en": "meat",
      "sv": "Kötträtter"
    },
    "name": {
      "en": "beef with curry",
      "sv": "Biff med Curry"
    },
    "price": {
      "sit": "149",
      "delivery": "129"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "13",
    "category": {
      "en": "meat",
      "sv": "Kötträtter"
    },
    "name": {
      "en": "beef with mushroom & bamboo shoots",
      "sv": "Biff m. Champinjoner & Bambuskott"
    },
    "price": {
      "sit": "149",
      "delivery": "129"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "14",
    "category": {
      "en": "meat",
      "sv": "Kötträtter"
    },
    "name": {
      "en": "beef with blackpepper or spicy sauce",
      "sv": "Biff m. Svartpeppar el. Starksås"
    },
    "price": {
      "sit": "149",
      "delivery": "129"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "15",
    "category": {
      "en": "meat",
      "sv": "Kötträtter"
    },
    "name": {
      "en": "beef with tomatoes",
      "sv": " Biff med Tomat"
    },
    "price": {
      "sit": "149",
      "delivery": "129"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "16",
    "category": {
      "en": "meat",
      "sv": "Kötträtter"
    },
    "name": {
      "en": "beef with paprika & soybeans",
      "sv": "Biff m. Paprika & Sojabönor"
    },
    "price": {
      "sit": "149",
      "delivery": "129"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "17",
    "category": {
      "en": "meat",
      "sv": "Kötträtter"
    },
    "name": {
      "en": "beef with mixed vegetables",
      "sv": "Biff Chop Suey (blandade grönsaker)"
    },
    "price": {
      "sit": "149",
      "delivery": "129"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "18",
    "category": {
      "en": "meat",
      "sv": "Kötträtter"
    },
    "name": {
      "en": "beef with broccoli",
      "sv": "Biff med Broccoli"
    },
    "price": {
      "sit": "149",
      "delivery": "129"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },

  // 20 - 27
  {
    "id": "20",
    "category": {
      "en": "meat",
      "sv": "Kötträtter"
    },
    "name": {
      "en": "deep fried pork with sweet & sour sauce",
      "sv": "Friterad Fläskfilé m. Sötsursås"
    },
    "price": {
      "sit": "139",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "21",
    "category": {
      "en": "meat",
      "sv": "Kötträtter"
    },
    "name": {
      "en": "marinated pork",
      "sv": "Marinerat Fläskkött"
    },
    "price": {
      "sit": "139",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "22",
    "category": {
      "en": "meat",
      "sv": "Kötträtter"
    },
    "name": {
      "en": "pork with curry",
      "sv": "Fläskfilé med Curry"
    },
    "price": {
      "sit": "139",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "23",
    "category": {
      "en": "meat",
      "sv": "Kötträtter"
    },
    "name": {
      "en": "pork with mushroom & bamboo shoots",
      "sv": "Fläskfilé m. Champinjoner & Bambuskott"
    },
    "price": {
      "sit": "139",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "24",
    "category": {
      "en": "meat",
      "sv": "Kötträtter"
    },
    "name": {
      "en": "pork with black pepper or spicy sauce",
      "sv": "Fläskfilé m. Svartpeppar el. Starksås"
    },
    "price": {
      "sit": "139",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "25",
    "category": {
      "en": "meat",
      "sv": "Kötträtter"
    },
    "name": {
      "en": "pork with tomatoes",
      "sv": "Fläskfilé med Tomat"
    },
    "price": {
      "sit": "139",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "26",
    "category": {
      "en": "meat",
      "sv": "Kötträtter"
    },
    "name": {
      "en": "pork with paprika & soybeans",
      "sv": "Fläskfilé m. Paprika & Sojabönor"
    },
    "price": {
      "sit": "139",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "27",
    "category": {
      "en": "meat",
      "sv": "Kötträtter"
    },
    "name": {
      "en": "pork with mixed vegetables",
      "sv": "Fläskfilé Chop Suey (blandade grönsaker)"
    },
    "price": {
      "sit": "139",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },

  // 14 Bird Dishes: 30 - 43

  {
    "id": "30",
    "category": {
      "en": "bird",
      "sv": "fågelrätter"
    },
    "name": {
      "en": "deep fried chicken with sweet & sour sauce",
      "sv": "Friterad Kyckling m. Sötsursås"
    },
    "price": {
      "sit": "139",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "31",
    "category": {
      "en": "bird",
      "sv": "fågelrätter"
    },
    "name": {
      "en": "chicken with oyster sauce",
      "sv": "Kyckling med Ostronsås"
    },
    "price": {
      "sit": "139",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "32",
    "category": {
      "en": "bird",
      "sv": "fågelrätter"
    },
    "name": {
      "en": "chicken with curry",
      "sv": "Kyckling med Curry"
    },
    "price": {
      "sit": "139",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "33",
    "category": {
      "en": "bird",
      "sv": "fågelrätter"
    },
    "name": {
      "en": "chicken with mushroom & bamboo shoots",
      "sv": "Kyckling m. Champinjoner & Bambuskott"
    },
    "price": {
      "sit": "139",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "34",
    "category": {
      "en": "bird",
      "sv": "fågelrätter"
    },
    "name": {
      "en": "chicken with spicy sauce",
      "sv": "Kyckling med Starksås"
    },
    "price": {
      "sit": "139",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "35",
    "category": {
      "en": "bird",
      "sv": "fågelrätter"
    },
    "name": {
      "en": "chicken with tomatoes",
      "sv": "Kyckling med Tomat"
    },
    "price": {
      "sit": "139",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "36",
    "category": {
      "en": "bird",
      "sv": "fågelrätter"
    },
    "name": {
      "en": "chicken with cashew nuts",
      "sv": "Kyckling med Cashewnötter"
    },
    "price": {
      "sit": "139",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "37",
    "category": {
      "en": "bird",
      "sv": "fågelrätter"
    },
    "name": {
      "en": "chicken with mixed vegetables",
      "sv": "Kyckling Chop Suey (blandade grönsaker)"
    },
    "price": {
      "sit": "139",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "38",
    "category": {
      "en": "bird",
      "sv": "fågelrätter"
    },
    "name": {
      "en": "chicken skewers with peanut sauce",
      "sv": "Kyckling på Spett m. Jordnötssås"
    },
    "price": {
      "sit": "139",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "39",
    "category": {
      "en": "bird",
      "sv": "fågelrätter"
    },
    "name": {
      "en": "chicken with pineapple",
      "sv": "Kyckling med Ananas"
    },
    "price": {
      "sit": "139",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "40",
    "category": {
      "en": "bird",
      "sv": "fågelrätter"
    },
    "name": {
      "en": "crispy roasted duck with mushrooms & hoisin sauce",
      "sv": "Grillad Anka m. Champinjoner(krispig anka på bädd av champinjoner & hoisinsås)"
    },
    "price": {
      "sit": "189",
      "delivery": "159"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "41",
    "category": {
      "en": "bird",
      "sv": "fågelrätter"
    },
    "name": {
      "en": "duck with paprika & soybeans",
      "sv": "Anka med Paprika & Sojabönor"
    },
    "price": {
      "sit": "189",
      "delivery": "159"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "42",
    "category": {
      "en": "bird",
      "sv": "fågelrätter"
    },
    "name": {
      "en": "crispy roasted duck with pineapple",
      "sv": "Grillad Anka med Ananas(krispig anka på bädd av ananas & sötsursås)"
    },
    "price": {
      "sit": "189",
      "delivery": "159"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "43",
    "category": {
      "en": "bird",
      "sv": "fågelrätter"
    },
    "name": {
      "en": "special HongKong roasted duck with mixed vegetables & hoisin sauce ",
      "sv": "Special Hong Kong Anka((krispig anka på bädd av blandade grönsaker & hoisinsås)"
    },
    "price": {
      "sit": "189",
      "delivery": "139"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },


  // 11 Seafood Dishes: 50, 52, 54,55, 56, 57, 58, 60, 62, 66, 67;
  {
    "id": "50",
    "category": {
      "en": "seafood",
      "sv": "havsrätter"
    },
    "name": {
      "en": "deep fried prawns with sweet & sour sauce",
      "sv": "Friterade Räkor m. Sötsursås"
    },
    "price": {
      "sit": "189",
      "delivery": "149"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "52",
    "category": {
      "en": "seafood",
      "sv": "havsrätter"
    },
    "name": {
      "en": "prawns with curry",
      "sv": "Räkor med Curry"
    },
    "price": {
      "sit": "189",
      "delivery": "149"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "54",
    "category": {
      "en": "seafood",
      "sv": "havsrätter"
    },
    "name": {
      "en": "prawns with spicy sauce",
      "sv": "Räkor med Starksås"
    },
    "price": {
      "sit": "189",
      "delivery": "149"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "55",
    "category": {
      "en": "seafood",
      "sv": "havsrätter"
    },
    "name": {
      "en": "prawns with tomatoes",
      "sv": "Räkor med Tomater"
    },
    "price": {
      "sit": "189",
      "delivery": "149"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "56",
    "category": {
      "en": "seafood",
      "sv": "havsrätter"
    },
    "name": {
      "en": "prawns with green pepper & soybeans",
      "sv": "Räkor m. Paprika & Sojabönor"
    },
    "price": {
      "sit": "189",
      "delivery": "149"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "57",
    "category": {
      "en": "seafood",
      "sv": "havsrätter"
    },
    "name": {
      "en": "prawns with mixed vegetables(alternatively add garlic flavor)",
      "sv": "Räkor Chop Suey (blandade grönsaker)"
    },
    "price": {
      "sit": "189",
      "delivery": "149"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "58",
    "category": {
      "en": "seafood",
      "sv": "havsrätter"
    },
    "name": {
      "en": "prawns with broccoli ",
      "sv": "Räkor med Broccoli"
    },
    "price": {
      "sit": "189",
      "delivery": "149"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "60",
    "category": {
      "en": "seafood",
      "sv": "havsrätter"
    },
    "name": {
      "en": "deep fried octupus with sweet & sour sauce",
      "sv": "Friterad Bläckfisk m. Sötsursås"
    },
    "price": {
      "sit": "139",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "62",
    "category": {
      "en": "seafood",
      "sv": "havsrätter"
    },
    "name": {
      "en": "octopus with curry",
      "sv": "Bläckfisk med Curry"
    },
    "price": {
      "sit": "139",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "66",
    "category": {
      "en": "seafood",
      "sv": "havsrätter"
    },
    "name": {
      "en": "octopus with paprika & soybeans",
      "sv": "Bläckfisk m. Paprika och Sojabönor"
    },
    "price": {
      "sit": "139",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "67",
    "category": {
      "en": "seafood",
      "sv": "havsrätter"
    },
    "name": {
      "en": "octopus with mixed vegetables",
      "sv": "Bläckfisk Chop Suey (blandade grönsaker)"
    },
    "price": {
      "sit": "139",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  // 7 Others: 70, 71, 73 - 77
  {
    "id": "70",
    "category": {
      "en": "others",
      "sv": "övrigt"
    },
    "name": {
      "en": "chinese special chow sam sing(prawns, chicken & pork with vegetables in a spicy sauce)",
      "sv": " Kinesisk Special Chow Sam Sing(räkor, kyckling & fläskfile med blandad grönsaker i starksås)"
    },
    "price": {
      "sit": "149",
      "delivery": "129"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "71",
    "category": {
      "en": "others",
      "sv": "övrigt"
    },
    "name": {
      "en": "fried rice with prawns & pork in curry flavour with a fried egg on top",
      "sv": "Nasi Goreng Special(stekt ris med räkor & fläsk i curry med stekt ägg & räkchips)"
    },
    "price": {
      "sit": "139",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "73",
    "category": {
      "en": "others",
      "sv": "övrigt"
    },
    "name": {
      "en": "fried noddles with chicken, beef or pork",
      "sv": "Stekta nudlar m. Kyckling, Biff el. Fläsk"
    },
    "price": {
      "sit": "128",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "74",
    "category": {
      "en": "others",
      "sv": "övrigt"
    },
    "name": {
      "en": "fried rice with prawns and vegetables",
      "sv": "Stekt Ris med Räkor"
    },
    "price": {
      "sit": "139",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "75",
    "category": {
      "en": "others",
      "sv": "övrigt"
    },
    "name": {
      "en": "four small dishes(deep fried prawns, chicken, pork with sweet & sour sauce, beef with mushrooms & bamboo)",
      "sv": "Fyra Små rätter"
    },
    "price": {
      "sit": "149",
      "delivery": "139"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "76",
    "category": {
      "en": "others",
      "sv": "övrigt"
    },
    "name": {
      "en": "four small dishes(deep fried prawns, chicken with sweet & sour sauce, beef with mushrooms & bamboo)",
      "sv": "Tre Små rätter"
    },
    "price": {
      "sit": "149",
      "delivery": "139"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "77",
    "category": {
      "en": "others",
      "sv": "övrigt"
    },
    "name": {
      "en": "mixed vegetables with cashew nuts",
      "sv": "Wokade Grönsaker"
    },
    "price": {
      "sit": "128",
      "delivery": "119"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  // 6 Dessert 80, 81, 83 - 86
  {
    "id": "80",
    "category": {
      "en": "dessert",
      "sv": "desserter"
    },
    "name": {
      "en": "deep fried banana and with cream",
      "sv": "Friterad Banan med Glass"
    },
    "price": {
      "sit": "49",
      "delivery": "49"
    },
    "hot": false,
    "popular": false,
    "special": false, 
  },
  {
    "id": "81",
    "category": {
      "en": "dessert",
      "sv": "desserter"
    },
    "name": {
      "en": "deep fried pineapple with ice cream",
      "sv": "Friterad Ananas med Glass"
    },
    "price": {
      "sit": "49",
      "delivery": "49"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "83",
    "category": {
      "en": "dessert",
      "sv": "desserter"
    },
    "name": {
      "en": "Ice cream with chocolate sauce",
      "sv": "GLASS MED CHOKLADSÄS"
    },
    "price": {
      "sit": "49",
      "delivery": "49"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "84",
    "category": {
      "en": "dessert",
      "sv": "desserter"
    },
    "name": {
      "en": "Ice cream with fruit cocktail",
      "sv": "GLASS MED FRUKTCOCKTAIL"
    },
    "price": {
      "sit": "49",
      "delivery": "49"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "85",
    "category": {
      "en": "dessert",
      "sv": "desserter"
    },
    "name": {
      "en": "Hong Kong special dessert",
      "sv": "HONG KONG SPECIAL DESSERT"
    },
    "price": {
      "sit": "79",
      "delivery": "79"
    },
    "hot": false,
    "popular": false,
    "special": false,
  },
  {
    "id": "86",
    "category": {
      "en": "dessert",
      "sv": "desserter"
    },
    "name": {
      "en": "BANANA SPLIT",
      "sv": "Banan med grädde, glass och chokladsas, Banana split"
    },
    "price": {
      "sit": "69",
      "delivery": "69"
    },
    "hot": false,
    "popular": false,
    "special": false,
  }
]