export const CategoriesData = [
    {
        "name": {
            "en": "starters",
            "sv": "Förrätter"
        },
        "img": require("../../pics/wonton-soup.jpg"),
        "description": "meat dishes in restaurantHongKong"
    },
    {
        "name": {
            "en": "meat",
            "sv": "Kötträtter"
        },
        "img": require("../../pics/noodle.jpg"),
        "description": "meat dishes in restaurantHongKong"
    },
    {
        "name": {
            "en": "bird",
            "sv": "fågelrätter"
        },
        "img": require("../../pics/soursauce-chicken.jpg"),
        "description": "bird dishes in restaurantHongKong"
    },
    {
        "name": {
            "en": "seafood",
            "sv": "havsrätter"
        },
        "img": require("../../pics/seafood.jpg"),
        "description": "seafood dishes in restaurantHongKong"
    },
    {
        "name": {
            "en": "others",
            "sv": "övrigt"
        },
        "img": require("../../pics/fried_rice.jpg"),
        "description": "other dishes in restaurantHongKong"
    },
    {
        "name": {
            "en": "dessert",
            "sv": "desserter"
        },
        "img": require("../../pics/dessert.jpg"),
        "description": "dessert in restaurantHongKong"
    }
]